import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/ui-elements/ui-elements/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { View } from 'react-native';
import { Icon } from '@components/Icon';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "icon"
    }}>{`Icon`}</h1>
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>
    <Props of={Icon} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic Usage`}</h2>
    <Playground __position={1} __code={'<Icon name=\"reaction-ole\" />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      View,
      Icon,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    <Icon name="reaction-ole" mdxType="Icon" />
    </Playground>
    <h2 {...{
      "id": "size"
    }}>{`Size`}</h2>
    <Playground __position={2} __code={'<View style={{ flexDirection: \'row\' }}>\n  <Icon name=\"home\" size=\"large\" />\n  <Icon name=\"home\" size=\"medium\" />\n  <Icon name=\"home\" size=\"small\" />\n</View>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      View,
      Icon,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <View style={{
        flexDirection: 'row'
      }} mdxType="View">
    <Icon name="home" size="large" mdxType="Icon" />
    <Icon name="home" size="medium" mdxType="Icon" />
    <Icon name="home" size="small" mdxType="Icon" />
  </View>
    </Playground>
    <h2 {...{
      "id": "custom-style"
    }}>{`Custom Style`}</h2>
    <Playground __position={3} __code={'<Icon name=\"logo\" style={{ fontSize: 110, color: \'#3BB64D\' }} />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      View,
      Icon,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Icon name="logo" style={{
        fontSize: 110,
        color: '#3BB64D'
      }} mdxType="Icon" />
    </Playground>
    <h2 {...{
      "id": "bottom-tab-navigation"
    }}>{`Bottom Tab Navigation`}</h2>
    <Playground __position={4} __code={'<View style={{ flexDirection: \'row\' }}>\n  <Icon name=\"home\" />\n  <Icon name=\"search\" />\n  <Icon name=\"add\" color=\"#3BB64D\" />\n  <Icon name=\"profile\" />\n  <Icon name=\"more\" />\n</View>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      View,
      Icon,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <View style={{
        flexDirection: 'row'
      }} mdxType="View">
    <Icon name="home" mdxType="Icon" />
    <Icon name="search" mdxType="Icon" />
    <Icon name="add" color="#3BB64D" mdxType="Icon" />
    <Icon name="profile" mdxType="Icon" />
    <Icon name="more" mdxType="Icon" />
  </View>
    </Playground>
    <h2 {...{
      "id": "bottom-tab-navigation-outline"
    }}>{`Bottom Tab Navigation Outline`}</h2>
    <Playground __position={5} __code={'<View style={{ flexDirection: \'row\' }}>\n  <Icon name=\"home-outline\" />\n  <Icon name=\"search-outline\" />\n  <Icon name=\"add-outline\" color=\"#3BB64D\" />\n  <Icon name=\"profile-outline\" />\n  <Icon name=\"more-outline\" />\n</View>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      View,
      Icon,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <View style={{
        flexDirection: 'row'
      }} mdxType="View">
    <Icon name="home-outline" mdxType="Icon" />
    <Icon name="search-outline" mdxType="Icon" />
    <Icon name="add-outline" color="#3BB64D" mdxType="Icon" />
    <Icon name="profile-outline" mdxType="Icon" />
    <Icon name="more-outline" mdxType="Icon" />
  </View>
    </Playground>
    <h2 {...{
      "id": "navigation"
    }}>{`Navigation`}</h2>
    <Playground __position={6} __code={'<View style={{ flexDirection: \'row\' }}>\n  <Icon name=\"chevron-left\" />\n  <Icon name=\"chevron-right\" />\n  <Icon name=\"expand-less\" />\n  <Icon name=\"expand-more\" />\n  <Icon name=\"close\" />\n</View>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      View,
      Icon,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <View style={{
        flexDirection: 'row'
      }} mdxType="View">
    <Icon name="chevron-left" mdxType="Icon" />
    <Icon name="chevron-right" mdxType="Icon" />
    <Icon name="expand-less" mdxType="Icon" />
    <Icon name="expand-more" mdxType="Icon" />
    <Icon name="close" mdxType="Icon" />
  </View>
    </Playground>
    <h2 {...{
      "id": "logos"
    }}>{`Logos`}</h2>
    <Playground __position={7} __code={'<Icon name=\"logo\" />\n<Icon name=\"logo-horizontal\" />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      View,
      Icon,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Icon name="logo" mdxType="Icon" />
  <Icon name="logo-horizontal" mdxType="Icon" />
    </Playground>
    <h2 {...{
      "id": "social"
    }}>{`Social`}</h2>
    <Playground __position={8} __code={'<View style={{ flexDirection: \'row\' }}>\n  <Icon name=\"facebook\" />\n  <Icon name=\"google\" />\n  <Icon name=\"apple\" />\n  <Icon name=\"envelope\" />\n</View>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      View,
      Icon,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <View style={{
        flexDirection: 'row'
      }} mdxType="View">
    <Icon name="facebook" mdxType="Icon" />
    <Icon name="google" mdxType="Icon" />
    <Icon name="apple" mdxType="Icon" />
    <Icon name="envelope" mdxType="Icon" />
  </View>
    </Playground>
    <h2 {...{
      "id": "avatar"
    }}>{`Avatar`}</h2>
    <Playground __position={9} __code={'<View style={{ flexDirection: \'row\' }}>\n  <Icon name=\"avatar\" />\n  <Icon name=\"avatar-female\" />\n  <Icon name=\"avatar-male\" />\n</View>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      View,
      Icon,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <View style={{
        flexDirection: 'row'
      }} mdxType="View">
    <Icon name="avatar" mdxType="Icon" />
    <Icon name="avatar-female" mdxType="Icon" />
    <Icon name="avatar-male" mdxType="Icon" />
  </View>
    </Playground>
    <h2 {...{
      "id": "misc"
    }}>{`Misc`}</h2>
    <Playground __position={10} __code={'<View style={{ flexDirection: \'row\' }}>\n  <Icon name=\"camera\" />\n  <Icon name=\"camera-outline\" />\n  <Icon name=\"notification\" />\n  <Icon name=\"notification-outline\" />\n</View>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      View,
      Icon,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <View style={{
        flexDirection: 'row'
      }} mdxType="View">
    <Icon name="camera" mdxType="Icon" />
    <Icon name="camera-outline" mdxType="Icon" />
    <Icon name="notification" mdxType="Icon" />
    <Icon name="notification-outline" mdxType="Icon" />
  </View>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      